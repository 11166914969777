<template>
  <div class="change-email-page">
    <van-nav-bar
      title="修改备用邮箱"
      left-arrow
      safe-area-inset-top
      placeholder
      @click-left="onClickLeft"
      :style="{'padding-top': statusBarHeight + 'px'}"
    />
    <van-cell-group>
      <van-form>
        <div class="change-email-box">
          <van-field
            v-model="email"
            name="邮箱"
            label="邮箱"
            placeholder="请输入新的邮箱"
            :rules="[{ required: true, message: '请输入新的邮箱' }]"
          />
        </div>
        <div style="margin: 16px;">
          <van-button
            round
            block
            type="primary"
            native-type="button"
            :loading="loginBtn"
            @click="onSubmit">发送验证邮件</van-button>
        </div>
      </van-form>

    </van-cell-group>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'ChangeEmail',
  components: {},
  data () {
    return {
      email: '',
      loginBtn: false
    }
  },
  computed: {
    ...mapState({
      statusBarHeight: state => state.global.statusBarHeight
    })
  },
  methods: {
    onSubmit() {
      console.log('submit')
      const that = this
      that.loginBtn = true
      const params = {
        email: this.email
      }
      this.$api.security.sendMail(params).then(res => {
        this.$notify({ type: 'success', message: '邮件已发送成功！' })
        that.loginBtn = false
        this.$router.push({ name: 'SecuritySetting' })
      }).catch(error => {
        that.loginBtn = false
        let errMsg = '修改密码失败！'
        if (error.response) {
          errMsg = error.response.data.message
        }
        this.$toast.fail(errMsg)
      })
    },
    onClickLeft () {
      // this.$router.push({ name: 'SecuritySetting' })
      window.history.go(-1)
    }
  },
  mounted () {
  }
}
</script>
<style lang="less">
.change-email-page {
  background-color: #f8f8f8;
  height: 100vh;
  background-image: url("~@/assets/background.png");
  background-size: 100% 40%;
  background-repeat: no-repeat;
  .van-nav-bar {
    background-color: transparent;
    &::after {
      border-bottom-width: 0
    }
    .van-nav-bar__content {
      height: 40px;
      .van-nav-bar__title {
        font-size: 19px;
        font-weight: bold;
        color: #ffffff;
        font-family: PingFang SC;
      }
      .van-nav-bar__left {
        .van-icon-arrow-left {
          color: #000000;
          font-weight: bold;
          color: #ffffff;
          &::before {
            vertical-align: middle;
          }
        }
      }
    }
  }
  .van-cell-group {
    background-color: transparent;
    overflow: auto;
    padding-top: 15px;
    .change-email-box {
      margin: 0 15px 15px 15px;
      background-color: #ffffff;
      border-radius: 5px;
      .van-cell {
        border-radius: 5px;
        .van-cell__title {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #333333;
          .van-cell__label {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #878787;
            line-height: 28px;
          }
        }
      }
    }
    &::after {
      border-top-width: 0
    }
  }
}
</style>
